/*
 *
 * SignupPage constants
 *
 */

enum ActionTypes {
  RESET_DEFAULT_STATE = 'app/SignupPage/RESET_DEFAULT_STATE',
  CHECK_EMAIL_SUBMIT = 'app/SignupPage/CHECK_EMAIL_SUBMIT',
  CHECK_EMAIL_REQUEST = 'app/SignupPage/CHECK_EMAIL_REQUEST',
  CHECK_EMAIL_SUCCESS = 'app/SignupPage/CHECK_EMAIL_SUCCESS',
  CHECK_EMAIL_FAILURE = 'app/SignupPage/CHECK_EMAIL_FAILURE',
  SIGNUP_SUBMIT = 'app/SignupPage/SIGNUP_SUBMIT',
  SIGNUP_REQUEST = 'app/SignupPage/SIGNUP_REQUEST',
  SIGNUP_SUCCESS = 'app/SignupPage/SIGNUP_SUCCESS',
  SIGNUP_FAILURE = 'app/SignupPage/SIGNUP_FAILURE',

  GET_LIST_SALES_PIC_SUCCESS = 'app/SignupPage/GET_LIST_SALES_PIC_SUCCESS',
}

export default ActionTypes;
