import { createSelector } from 'reselect';
import { ApplicationRootState } from 'types';
import { initialState } from './reducer';

/**
 * Direct selector to the signupPage state domain
 */

const selectSignupPageDomain = (state: ApplicationRootState) => {
  return state ? state.signupPage : initialState;
};

/**
 * Other specific selectors
 */

/**
 * Default selector used by SignupPage
 */

const selectSignupPage = () =>
  createSelector(selectSignupPageDomain, substate => {
    return substate;
  });

export default selectSignupPage;
export { selectSignupPageDomain };
